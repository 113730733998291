<template>
  <v-card max-width="768" class="mx-auto grey darken-2 fill-height">
    <v-app-bar app dark dense max-width="768" class="mx-auto">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="ml-n5 mr-n2"></v-app-bar-nav-icon>
      <router-link to="/">
        <v-avatar size="40" class="ma-0 mr-1 pa-0">
        <img :src="require('@/assets/images/agents-of-anarchy.png')" />
      </v-avatar>
      <span class="font-weight-bold">PHILT3R</span></router-link>
      <v-spacer></v-spacer>
      <!-- <v-btn icon to="/chat">
        <v-icon>mdi-forum-outline</v-icon>
      </v-btn> -->
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      app
    >
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="group"
          active-class="grey--text text--accent-4"
        >
          <v-list-item to="/">
            <v-list-item-title>Agent of Anarchy</v-list-item-title>
          </v-list-item>
          <v-list-item to="/bio">
            <v-list-item-title>Bio</v-list-item-title>
          </v-list-item>
          <v-list-item to="/tunes">
            <v-list-item-title>Recordings</v-list-item-title>
          </v-list-item>
          <v-list-item to="/photos">
            <v-list-item-title>Photos</v-list-item-title>
          </v-list-item>
          <v-list-item to="/videos">
            <v-list-item-title>Videos</v-list-item-title>
          </v-list-item>
          <v-list-item to="/posters">
            <v-list-item-title>Posters</v-list-item-title>
          </v-list-item>
          <v-list-item to="/comics">
            <v-list-item-title>Comics</v-list-item-title>
          </v-list-item>
          <v-list-item to="/art">
            <v-list-item-title>Art</v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item href="https://www.mixcloud.com/philt3r/">
            <v-list-item-title>Mixcloud</v-list-item-title>
            <v-list-item-avatar size="25">
              <v-img src="https://cdn.prod.website-files.com/60b3be0a84c6fc953fe10a1a/60b5fdd311526d0d6d4bd49f_favicon_output32px.png"></v-img>
            </v-list-item-avatar>
          </v-list-item>
          <v-list-item href="https://holo.host/">
            <v-list-item-title>Holo</v-list-item-title>
            <v-list-item-avatar size="25">
              <v-img src="https://holo.host/wp-content/themes/holo/images/favicon/favicon-16x16.png"></v-img>
            </v-list-item-avatar>
          </v-list-item>
          <v-list-item href="https://www.holochain.org/">
            <v-list-item-title>Holochain</v-list-item-title>
            <v-list-item-avatar size="25">
              <v-img src="https://www.holochain.org/img/favicon.ico"></v-img>
            </v-list-item-avatar>
          </v-list-item>
          <v-list-item href="https://www.ioen.tech/">
            <v-list-item-title>Internet Of ENergy</v-list-item-title>
            <v-list-item-avatar size="25">
              <v-img src="https://cdn.prod.website-files.com/62f1416b3d1cea4b8c35ede6/63056759bd83e6901e91cb81_logo32.png"></v-img>
            </v-list-item-avatar>
          </v-list-item>
          <v-list-item href="http://philipbeadle.life/">
            <v-list-item-title>Philip Beadle</v-list-item-title>
            <v-list-item-avatar size="25">
              <v-img src="https://avatars.githubusercontent.com/u/5264862?s=40&v=4"></v-img>
            </v-list-item-avatar>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <default-view />
  </v-card>
</template>

<script>
export default {
  name: 'DefaultLayout',
  components: {
    DefaultView: () => import('./View')
  },
  data () {
    return {
      drawer: false,
      group: null
    }
  },
  watch: {
    $route (to, from) {
      this.drawer = false
    }
  }
}
</script>
